<template>
  <div class="app">
    <Menu :list="menuList"/>

    <div class="px-5 sm:px-52 mx-auto my-20">
      <div style="font-size: 20px">
        <div class="mt-10">
          <h2 class="font-extrabold color-purple text-3xl RobotoMedium" >Aviso de Fraude</h2>
          <div class="liner-yellow"></div>
        </div>
      </div>

      <br>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Antes de ahorrar en una SOCAP, cerciórate que ese encuetra en el registro que lleva el Fideicomiso del Fondo de Supervisión Auxiliar de Sociedades Cooperativas de Ahorro y Préstamos y de Protección a sus Ahorradores FOCOP en su página. <a class="a_links" href="https://focoop.com.mx/WebSite16/WebForms/NivelCapitalizaB.aspx" target="_blank">https://focoop.com.mx/WebSite16/WebForms/NivelCapitalizaB.aspx</a></li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Compara todas las opciones posibles antes de asociarte a una SOCAP.</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Si quieres ahorrar en una SOCAP de Nivel Básico. debes asegurarte de tener carácter de socio y se te proporcione el certificado que acredita como sicio</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Recuerda que el ser socio de una SOCAP se te invite a participar de forma activa en la toma de decisiones de la Sociedad y en la elección de su Consejo de Administración y Consejo de Vigilancia, además de que te den voz y voto en las Asambleas Generales de Socios.</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Receurda que la mayor parte de los problemas de captación indebida, insolvencia y fraudes realizados a socios en una SOCAP, se han cometido en entidades que no tienen autorización y no están en proceso de regularización ante el FOCCOP,ni están inscritas ene el registro que al efecto administra FOCOOP. <a class="a_links" href="http://focoop.com.mx/WebSite16/WebForms/RegistroInfo.aspx" target="_blank">http://focoop.com.mx/WebSite16/WebForms/RegistroInfo.aspx</a></li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Antes de elegir una Caje de Ahorro, Investiga que institución te da una mayor tasa de interés, mejor servicio y te cobra menos comisiones</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Revisar, ordenar y guardar todos los documentos que amparan tus cuentas de ahorro, como el contrato, tus estados de cuenta y las fichas de depósito.</li>
        </div>
      </div>

      <div class="mt-10">
        <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Leyes aplicables para las SOCAP</h3>
      </div>

      <br>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Ley para Regular las Actividades de las Sociedades Cooperativas de Ahorro y Préstamos (LRASCAP).</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Ley de la Comisión Nacional Bancaria y de Valores.</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Ley de Protección y Defensa al Usuario de Servicios Financieros.</li>
        </div>
      </div>

      <div>
        <div class="mx-3 my-2">
          <li class="color-grey text-info" type="disc">Ley para la Transparencia y Ordenamiento de los Servicios Financieros</li>
        </div>
      </div>

      <br>

      <div>
        <div class="mx-3 my-2">
          En caso de tener alguna duda puedes comunicarte con nosotros al Teléfono: (644) 4164346 con horario de atención de 8:00 a 3:00 de Lunes a Viernes, enviarnos un correo electrónico clientes@foncabsa.com , o visitarnos en Bahía San Carlos #1056, Colonia Prados del Tepeyac, CP 85150, Cajeme, Ciudad Obregón, Sonora.<br>
          Recuerda que al acudir a Sociedades cooperativas de Ahorro y Préstamo debidamente registradas y autorizadas te da la seguridad de que, en caso de que surja algún problema o inconformidad, puedas presentar tu queja ante la CONDUSEF <a class="a_links" href="https://www.gob.mx/condusef#669" target="_blank">https://www.gob.mx/condusef#669</a>
        </div>
      </div>

    </div>

    <a name="footer"></a>
    <Footer/>

  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import Footer from '@/components/Footer3.vue';
import Menu from '@/components/Menu.vue';
import ListTexts from '@/components/ListaTextos.vue';
import menuOptions  from "@/helpers/menu.js";

export default {

  name: 'AvisoFraude',
  setup(){
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };
    
    return {
      menuList,
      list: [
        `• Antes de ahorrar en una SOCAP, cerciórate que ese encuetra en el registro que lleva el Fideicomiso del Fondo de Supervisión Auxiliar de Sociedades Cooperativas de Ahorro y Préstamos y de Protección a sus Ahorradores FOCOP en su página. <a class="a_links" href="https://focoop.com.mx/WebSite16/WebForms/NivelCapitalizaB.aspx" target="_blank">https://focoop.com.mx/WebSite16/WebForms/NivelCapitalizaB.aspx </a> <br><hr>`,
        `• Compara todas las opciones posibles antes de asociarte a una SOCAP. <br><hr>`,
        `• Si quieres ahorrar en una SOCAP de Nivel Básico. debes asegurarte de tener carácter de socio y se te proporcione el certificado que acredita como sicio, <hr>`,
        `• Recuerda que el ser socio de una SOCAP se te invite a participar de forma activa en la toma de decisiones de la Sociedad y en la elección de su Consejo de Administración y Consejo de Vigilancia, además de que te den voz y voto en las Asambleas Generales de Socios. <hr>`,
        `• Receurda que la mayor parte de los problemas de captación indebida, insolvencia y fraudes realizados a socios en una SOCAP, se han cometido en entidades que no tienen autorización y no están en proceso de regularización ante el FOCCOP,ni están inscritas ene el registro que al efecto administra FOCOOP. <a class="a_links" href="http://focoop.com.mx/WebSite16/WebForms/RegistroInfo.aspx" target="_blank">http://focoop.com.mx/WebSite16/WebForms/RegistroInfo.aspx</a> <hr>`,
        `• Antes de elegir una Caje de Ahorro, Investiga que institución te da una mayor tasa de interés, mejor servicio y te cobra menos comisiones. <hr>`,
        `• Revisar, ordenar y guardar todos los documentos que amparan tus cuentas de ahorro, como el contrato, tus estados de cuenta y las fichas de depósito.<hr>`
      ],

      subList: [
        `• Ley para Regular las Actividades de las Sociedades Cooperativas de Ahorro y Préstamos (LRASCAP). <br><hr>`,
        `• Ley de la Comisión Nacional Bancaria y de Valores. <br><hr>`,
        `• Ley de Protección y Defensa al Usuario de Servicios Financieros. <br><hr>`,
        `• Ley para la Transparencia y Ordenamiento de los Servicios Financieros<br><hr>`
      ]
    }
  },
  components: {
    Menu,
    ListTexts,
    Footer
  }
}
</script>

<style  >
  .text-information {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 22px;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .margin-text-information {
    margin: 30px;
  }

  .text-information {
    font-size: 16px;
  }

  .a_links { color: #FDCE00 !important; } */

  .a_links:hover { color: #23527c !important; }
  
</style>

<style scoped>
.liner-yellow {
  width: 100px;
  background: #f8c603;
  height: 5px;
}
.text-info{
  font-family: "RobotoRegular";
}
.color-purple {
  color: #810042;
}
.color-grey {
  color: #726659;
}
</style>